import type { FC, ReactElement } from 'react';

import { Box, PaletteMode, SvgIcon, Tooltip, useTheme } from '@mui/material';
import Moon01Icon from '@untitled-ui/icons-react/build/esm/Moon01';
import SunIcon from '@untitled-ui/icons-react/build/esm/Sun';

interface Option {
  label: string;
  value: PaletteMode;
  icon: ReactElement;
}

const options: Option[] = [
  {
    label: 'Light',
    value: 'light',
    icon: <SunIcon />,
  },
  {
    label: 'Dark',
    value: 'dark',
    icon: <Moon01Icon />,
  },
];

interface OptionsColorSchemeProps {
  onChange?: (value: PaletteMode) => void;
  value?: PaletteMode;
}

export const OptionsColorSchemeSwitcher: FC<OptionsColorSchemeProps> = (props) => {
  const { onChange, value } = props;
  const theme = useTheme();

  const handleClick = () => {
    if (value === 'light') {
      onChange?.('dark');
    } else {
      onChange?.('light');
    }
  };

  const activeOption = options.find((option) => option.value === value);

  return (
    <Tooltip title={`Theme Switcher - Active: ${activeOption?.label}`}>
      <Box style={{ padding: 2 }}>
        <SvgIcon
          style={{ width: 35 }}
          onClick={handleClick}
          fontSize="small"
          sx={{
            cursor: 'pointer',
            '&:hover': {
              color: theme.palette.primary.main,
            },
          }}
        >
          {activeOption?.icon}
        </SvgIcon>
      </Box>
    </Tooltip>
  );
};
