import { type FC, type ReactNode, useContext } from 'react';

import { Box, Container, PaletteMode, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { Logo } from '@components/Logo';
import { RouterLink } from '@components/router-link';
import { OptionsColorSchemeSwitcher } from '@components/settings/settings-drawer/options-color-scheme-switch';

import { LanguageSwitch } from '@layouts/dashboard/language-switch';

import { paths } from '@paths/index';

import { SettingsContext } from '@contexts/settings-context';

const TOP_NAV_HEIGHT = 64;

const LayoutRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'top center',
  backgroundImage: 'url("/assets/gradient-bg.svg")',
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  height: '100%',
}));

interface LayoutProps {
  children: ReactNode;
}

export const Layout: FC<LayoutProps> = (props) => {
  const { children } = props;
  const settings = useContext(SettingsContext);

  const handleColorSchemeChange = (value: PaletteMode) => {
    settings.handleUpdate({ paletteMode: value });
  };

  return (
    <LayoutRoot>
      <Box
        component="header"
        sx={{
          left: 0,
          position: 'fixed',
          right: 0,
          top: 0,
          zIndex: (theme) => theme.zIndex.appBar,
        }}
      >
        <Container style={{ marginTop: 32 }}>
          <Stack
            spacing={2}
            direction="row"
            sx={{ height: TOP_NAV_HEIGHT, justifyContent: 'space-between' }}
          >
            <Stack
              alignItems="center"
              component={RouterLink}
              direction="row"
              display="inline-flex"
              href={paths.index}
              spacing={1}
              sx={{ textDecoration: 'none' }}
            >
              <Box>
                <Logo />
              </Box>
            </Stack>
            <Stack sx={{ justifyContent: 'center' }}>
              <Box
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 2 }}
              >
                <OptionsColorSchemeSwitcher
                  onChange={handleColorSchemeChange}
                  value={settings.paletteMode}
                />

                <LanguageSwitch />
              </Box>
            </Stack>
          </Stack>
        </Container>
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          flex: '1 1 auto',
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            py: {
              xs: '60px',
              md: '120px',
            },
          }}
        >
          {children}
        </Container>
      </Box>
    </LayoutRoot>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};
