import type { FC } from 'react';

import { Box, IconButton, Tooltip } from '@mui/material';
import { useLocale } from 'next-intl';
import Image from 'next/image';

import { usePopover } from '@hooks/use-popover';

import { LanguagePopover } from './language-popover';

export type Language = 'pl-PL' | 'en-US';

export const languages: Record<Language, string> = {
  'en-US': '/assets/flags/flag-us.svg',
  'pl-PL': '/assets/flags/flag-pl.svg',
};

export const LanguageSwitch: FC = () => {
  const locale = useLocale();
  const popover = usePopover<HTMLButtonElement>();

  const flag = languages[locale as Language];

  return (
    <>
      <Tooltip title="Language">
        <IconButton onClick={popover.handleOpen} ref={popover.anchorRef}>
          <Box
            sx={{
              width: 35,
              '& img': {
                width: '100%',
              },
            }}
          >
            <Image alt={locale} width={60} height={30} src={flag} />
          </Box>
        </IconButton>
      </Tooltip>
      <LanguagePopover
        anchorEl={popover.anchorRef.current}
        onClose={popover.handleClose}
        open={popover.open}
      />
    </>
  );
};
