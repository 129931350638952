import type { FC } from 'react';
import { useCallback } from 'react';
import { toast } from 'react-hot-toast';

import { Box, ListItemIcon, ListItemText, MenuItem, Popover, Typography } from '@mui/material';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import PropTypes from 'prop-types';

import { tokens } from '@translations/tokens';

import { Language } from './index';

type LanguageOptions = {
  [key in Language]: {
    icon: string;
    label: string;
  };
};

const languageOptions: LanguageOptions = {
  'en-US': {
    icon: '/assets/flags/flag-us.svg',
    label: 'English US',
  },
  'pl-PL': {
    icon: '/assets/flags/flag-pl.svg',
    label: 'Polski',
  },
};

interface LanguagePopoverProps {
  anchorEl: null | Element;
  onClose?: () => void;
  open?: boolean;
}

export const LanguagePopover: FC<LanguagePopoverProps> = (props) => {
  const { anchorEl, onClose, open = false, ...other } = props;
  const t = useTranslations();
  const path = usePathname();

  const handleChange = useCallback(async (): Promise<void> => {
    onClose?.();
    toast.success(t(tokens.common.languageChanged));
  }, [onClose, t]);

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom',
      }}
      disableScrollLock
      transformOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 220 } }}
      {...other}
    >
      {(Object.keys(languageOptions) as Language[]).map((language) => {
        const option = languageOptions[language];

        return (
          <Link
            style={{ textDecoration: 'none', color: 'white' }}
            locale={language}
            key={language}
            href={path}
          >
            <MenuItem onClick={() => handleChange()}>
              <ListItemIcon>
                <Box
                  sx={{
                    width: 28,
                    '& img': {
                      width: '100%',
                    },
                  }}
                >
                  <Image alt={option.label} src={option.icon} width={60} height={30} />
                </Box>
              </ListItemIcon>
              <ListItemText primary={<Typography variant="subtitle2">{option.label}</Typography>} />
            </MenuItem>
          </Link>
        );
      })}
    </Popover>
  );
};

LanguagePopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
